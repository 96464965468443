import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";

export const useEnvironmentsResource = () => {
    const { createInstanceService } = useDic();
    return useQueryLoadable(async () => {
        return createInstanceService
            .fetchEnvironments()
            .then(getData)
            .then(data => {
                try {
                    return data.map(({ name }) => ({
                        label: name,
                        value: name,
                    }));
                } catch (_) {
                    return [];
                }
            });
    }, [createInstanceService]);
};

export const useInstanceEnvironmentsOptionsResource = ({
    canSetAdvancedOptions,
}) => {
    const { createInstanceService } = useDic();
    return useQueryLoadable(async () => {
        return Promise.all([
            createInstanceService.fetchEnvironments().then(getData),
            createInstanceService.fetchTiers().then(getData),
            createInstanceService.fetchStorages().then(getData),
        ]);
    }, [canSetAdvancedOptions, createInstanceService]);
};
