import qs from "qs";
import { axiosService } from "../axios";

export const createInstanceService = {
    fetchEnvironments() {
        return axiosService.get(`/api/instances/environments`);
    },
    fetchTiers() {
        return axiosService.get(`/api/instances/tier-options`);
    },
    fetchProfiles() {
        return axiosService.get(`/api/instances/profiles`);
    },
    fetchProviders({ accountId }) {
        const query = qs.stringify({ accountId });
        return axiosService.get(`/api/instances/cloud-providers?${query}`);
    },
    fetchStorages() {
        return axiosService.get(`/api/instances/storages`);
    },
};
